import { navigate } from 'gatsby';
import { useEffect } from 'react';

const IndexPage = (): null => {
  useEffect(() => {
    if (window) {
      navigate(String(process.env.GATSBY_REDIRECT_UNLOGED));
    }
  }, []);

  return null;
};

export default IndexPage;
